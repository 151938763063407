// Projects.jsx
import React from 'react';
//import code from '../assets/code2.png';
//import pokefs from '../assets/img/project_imgs/pokePre.png'
import bookbot from '../assets/img/project_imgs/pyPre.avif'
import fscareer from '../assets/img/project_imgs/fscPre.png'
import docx from '../assets/img/project_imgs/docPre.png'
import bookclub from '../assets/img/project_imgs/bookclubPre.jpeg'
import weather from '../assets/img/project_imgs/weatherPre.png'
import cern from '../assets/img/project_imgs/myshiftPre.png'
import ems from '../assets/img/project_imgs/homescreen.png'
import health from '../assets/img/project_imgs/healthcare.png'
import ssg from '../assets/img/project_imgs/web_dev_basics.png'

const Projects = () => {
  return (
    <div name='projects' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8 w-full flex justify-center items-center flex-col'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-cyan-500'>
            Projects
          </p>
          <p className='py-6 text-2xl'>Check out some of my most recent projects</p>
        </div>
{/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
            {/* Grid Item */}

          <div
            style={{ backgroundImage: `url(${cern})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'>
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Full Stack Web App
              </span>
              <p className='text-center'>An employee time tracking app. Built using React, CSS, HTML, Apollo, and graphql for API querying.</p>
              <div className='pt-8 text-center'>
                <a href='https://www.youtube.com/watch?v=yBBKDqeg8ZM'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/chergul/MyShiftrack'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${bookbot})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Python CLI Tool
              </span>
              <p className='text-center'>a Python program that can analyze an entire book and print out an insightful statistical report.</p>
              <div className='pt-8 text-center'>
                
                <a href='https://github.com/NateJonesIII/bookBotPy'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${fscareer})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Front-End UI
              </span>
              <p className='text-center'>Live modern job posting utilizing PHP, JS, CSS, and plugins.</p>
              <div className='pt-8 text-center'>
                <a href='https://fsstudio.com/careers/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Live
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${docx})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Python Tool App
              </span>
              <p className='text-center'>This Python program is designed to quickly generate a "Welcome" word document in docX format for [Company Name] employee user accounts.</p>
              <div className='pt-8 text-center'>
                <a href='https://github.com/NateJonesIII/docX_Gen?tab=readme-ov-file#preview'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Preview
                  </button>
                </a>
                <a href='https://github.com/NateJonesIII/docX_Gen?tab=readme-ov-file#code-snippets'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${ssg})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Static Site Generator
              </span>
              <p className='text-center'>a project born out of the desire to empower creators, developers, and enthusiasts alike to build blazing-fast websites with the elegance of minimalism.</p>
              <div className='pt-8 text-center'>
                <a href='https://github.com/NateJonesIII/staticsitegenerator'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${bookclub})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              RoundTable Website
              </span>
              <p className='text-center'>An online book club that allows the user to discuss the current book of the month with other users, leave ratings and choose the following months book up for discussion.</p>
              <div className='pt-8 text-center'>
                <a href='https://www.youtube.com/watch?v=zWadtU1WGQI'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/NateJonesIII/book-club'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${weather})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              Weather Dashboard
              </span>
              <p className='text-center'>A web application that allows users to search for weather forecasts of various cities. It provides real-time weather data along with an extended-day forecast. </p>
              <div className='pt-8 text-center'>
                <a href='https://natejonesiii.github.io/City-Forecast/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/NateJonesIII/City-Forecast'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${ems})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
              Event Management System
              </span>
              <p className='text-center'> A web application built using Django, designed to manage events with functionalities such as creating events, listing events, and viewing event details. </p>
              <div className='pt-8 text-center'>
                <a href='https://mn-ems-kw2es23pba-uc.a.run.app'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a href='https://github.com/NateJonesIII/mn_ems'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${health})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
              <span className=' text-lg font-bold text-white tracking-wider'>
                Front-End UI
              </span>
              <p className='text-center'>Live modern healthcare services page utilizing PHP, JS, CSS, and jquery.</p>
              <div className='pt-8 text-center'>
                <a href='https://fsstudio.com/healthcare/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Live
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Projects;