import React from 'react';
import pythonSVG from '../assets/svgs/python.svg';
import sassSVG from '../assets/svgs/sass.svg';
import htmlSVG from '../assets/svgs/html.svg';
import cssSVG from '../assets/svgs/css.svg';
import jsSVG from '../assets/svgs/javascript.svg';
import reactSVG from '../assets/svgs/react.svg';
import sqlSVG from '../assets/svgs/sql.svg';
import gqlSVG from '../assets/svgs/graphql.svg';
import dockerSVG from '../assets/svgs/docker.svg';
import mdbSVG from '../assets/svgs/mongodb.svg';
import nodeSVG from '../assets/svgs/nodejs.svg';
import githubSVG from '../assets/svgs/github.svg';
import gitSVG from '../assets/svgs/git.svg';
import expressSVG from '../assets/svgs/expressjs.svg';
import jsonSVG from '../assets/svgs/json.svg';
import handleSVG from '../assets/svgs/handlebarsjs.svg';
import jqSVG from '../assets/svgs/jquery.svg';
import javaSVG from '../assets/svgs/java.svg';
import bsSVG from '../assets/svgs/bootstrap.svg';
import heroSVG from '../assets/svgs/heroku.svg';
import insomniaSVG from '../assets/svgs/insomnia.svg';
import postSVG from '../assets/svgs/postman.svg';
import mysqlSVG from '../assets/svgs/mysql.svg';
import azureSVG from '../assets/svgs/azure-2.svg';
import gcpSVG from '../assets/svgs/google-cloud-1.svg';
import awsSVG from '../assets/svgs/amazon-web-services-2.svg';
import pgresSVG from '../assets/svgs/postgresql.svg';
import ibmSVG from '../assets/svgs/ibm-cloud-1.svg';
import k8SVG from '../assets/svgs/kubernets.svg';
import comptia from '../assets/img/pngs/SecurityPlus Logo Certified CE.png';
import umBadge from '../assets/img/pngs/um_fs_badge_LG.png'
import tailwSVG from '../assets/svgs/tailwindcss-icon.svg'
import bulmaSVG from '../assets/svgs/bulma_svg.svg'
import wpSVG from '../assets/svgs/wordpress-icon.svg'

const Skills = () => {
  return (
    <div name='skills' className='w-full h-auto bg-[#0a192f] text-gray-300'>
      <div className='max-w-[1100px] mx-auto p-4 flex flex-col justify-center w-full'>
        <div className='w-full flex justify-center items-center flex-col mb-7'>
          <p className='text-4xl font-bold inline border-b-4 border-cyan-500 text-center'>Skills</p>
          <p className='py-4 text-2xl sm:text-center'>I enjoy diving into and learning new things. Here's a list of technologies I've worked with</p>
        </div>

        {/* Languages Section */}
        <div className='mb-10'>
          <h4 className='text-2xl font-semibold mb-4'>Languages</h4>
          <div className='w-full grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 text-center items-center py-8'>
            {renderSkill(htmlSVG, 'HTML5')}
            {renderSkill(cssSVG, 'CSS3')}
            {renderSkill(jsSVG, 'JavaScript')}
            {renderSkill(pythonSVG, 'Python')}
            {renderSkill(javaSVG, 'Java')}
            {renderSkill(sqlSVG, 'SQL')}
          </div>
        </div>

        {/* Libraries Section */}
        <div className='mb-10'>
          <h4 className='text-2xl font-semibold mb-4'>Libraries</h4>
          <div className='w-full grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 text-center items-center py-8'>
            {renderSkill(reactSVG, 'React')}
            {renderSkill(jqSVG, 'jQuery')}
            {renderSkill(jsonSVG, 'JSON')}
            {renderSkill(handleSVG, 'Handlebars.js')}
            {renderSkill(sassSVG, 'Sass')}
            {renderSkill(gqlSVG, 'GraphQL')}
          </div>
        </div>

        {/* Frameworks Section */}
        <div className='mb-10'>
          <h4 className='text-2xl font-semibold mb-4'>Frameworks</h4>
          <div className='w-full grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 text-center items-center py-8'>
            {renderSkill(expressSVG, 'Express.js')}
            {renderSkill(nodeSVG, 'Node.js')}
            {renderSkill(bsSVG, 'Bootstrap')}
            {renderSkill(k8SVG, 'kubernetes')}
            {renderSkill(tailwSVG, 'Tailwind CSS')}
            {renderSkill(bulmaSVG, 'Bulma CSS')}
          </div>
        </div>

        {/* Tools Section */}
        <div className='mb-10'>
          <h4 className='text-2xl font-semibold mb-4'>Tools</h4>
          <div className='w-full grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 text-center items-center py-8'>
            {renderSkill(gitSVG, 'Git')}
            {renderSkill(githubSVG, 'GitHub')}
            {renderSkill(dockerSVG, 'Docker')}
            {renderSkill(postSVG, 'Postman')}
            {renderSkill(insomniaSVG, 'Insomnia')}
            {renderSkill(wpSVG, 'WordPress')}
          </div>
        </div>

        {/* Cloud Services Section */}
        <div className='mb-10'>
          <h4 className='text-2xl font-semibold mb-4'>Cloud Services</h4>
          <div className='w-full grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 text-center items-center py-8'>
            {renderSkill(heroSVG, 'Heroku')}
            {renderSkill(azureSVG, 'Azure Cloud')}
            {renderSkill(gcpSVG, 'Google Cloud')}
            {renderSkill(awsSVG, 'AWS')}
            {renderSkill(ibmSVG, 'IBM Cloud')}
          </div>
        </div>

        {/* Databases Section */}
        <div className='mb-10'>
          <h4 className='text-2xl font-semibold mb-4'>Databases</h4>
          <div className='w-full grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 text-center items-center py-8'>
            {renderSkill(mdbSVG, 'MongoDB')}
            {renderSkill(mysqlSVG, 'MySQL')}
            {renderSkill(pgresSVG, 'PostgreSQL')}
          </div>
        </div>

        {/* Certifications Section */}
        <div className='mb-10'>
          <h4 className='text-2xl font-semibold mb-4'>Certifications</h4>
          <div className='w-full grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 text-center items-center py-8'>
            {renderSkill(comptia, 'Security+')}
            {renderSkill(umBadge, 'Full Stack Dev')}
          </div>
        </div>
      </div>
    </div>
  );
};

// to wrap all skills in card form, minimizes
const renderSkill = (src, alt) => (
  <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 flex flex-col justify-center items-center'>
    <div className="my-4">
      <img className="h-20 w-20" src={src} alt={`${alt} Logo`} />
    </div>
    <div className="my-4">
      <p className='my-4'>{alt}</p>
    </div>
  </div>
);


export default Skills;