import React, { useState } from 'react'; 
import {
  FaBars,
  FaTimes,
  FaGithub,
 //FaLinkedin,
} from 'react-icons/fa';

//import { FcViewDetails } from "react-icons/fc";
import { ImFileText } from "react-icons/im";
import { HiOutlineMail } from 'react-icons/hi';
import { Link } from 'react-scroll';
import scilogo from "../assets/img/pngs/smLogo_Transparent.png"


const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className='fixed w-full h-[75px] flex justify-between items-center  px-4 bg-[#27b6f3] text-gray-300 z-10'>
      <div>
        <img className="bg-transparent w-20 ml-auto sm:justify-start" src={scilogo} alt="Logo"/>
      </div>
      {/* menu */}
      <ul className='hidden md:flex gap-x-8 font-bold '>
        <li className='hover:text-blue-900'>
          <Link to='home' smooth={true} duration={500}>
            Main
          </Link>
        </li>
        <li className='hover:text-blue-900'>
          <Link to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='hover:text-blue-900'>
          <Link to='projects' smooth={true} duration={500}>
            Projects
          </Link>
        </li>
        <li className='hover:text-blue-900'>
          <Link to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='hover:text-blue-900'>
          <Link to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
      
      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars className='h-8 w-8' /> : <FaTimes className='h-8 w-8' />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'
        }
      >
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='projects' smooth={true} duration={500}>
            Projects
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li> 
        <li className='py-6 text-4xl'>
          {' '}
          <a href='https://www.credly.com/badges/f3de5a4a-bcce-45b7-95ef-f0dfe74aa55c/linked_in_profile' smooth={true} duration={500}>
            Security+ 
          </a>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <a href='https://www.credential.net/ecb74684-5872-405d-883a-69eb7b734be4' smooth={true} duration={500}>
            UM Certification
          </a>
        </li>
      </ul>

      {/* Social icons */}
      <div className='hidden lg:flex fixed flex-col top-[25%] left-0'>
        <ul> {/*
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='#'
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li> */}
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://github.com/NateJonesIII'
            >
              Github <FaGithub size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#1d8076]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='mailto:inquire@devnjones.me?body=Hey Nate!?subject=Opportunity Calling:'
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#000000]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://www.credly.com/badges/f3de5a4a-bcce-45b7-95ef-f0dfe74aa55c/linked_in_profile'
            >
              Sec+ <ImFileText  size={30} />
            </a>
          </li>

        </ul>
      </div>
    </div>
  );
};
export default Navbar;