import React, { useState } from 'react';
import 'animate.css';
import './Contact.css';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_302kght', 'template_ezssm6n', formData, 'qFJrvF51AIxJUht9b')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
      }, (err) => {
        console.error('FAILED...', err);
        alert('Failed to send email.');
      });

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
      <div className='flex flex-col max-w-[600px] w-full'>
        <div className='pb-8 flex flex-col justify-center w-full h-full items-center'>
          <div className='flex items-center'>
            <p className='text-4xl font-bold inline border-b-4 border-cyan-500 text-gray-300 animate__animated animate__fadeInDown'>Contact</p>
            <span className='text-4xl ml-2 animate-bounce'>✉️</span>
          </div>
          <p className='text-gray-300 py-4 animate__animated animate__fadeInUp'>Send me a message</p>
        </div>
        <form className='w-full flex flex-col animate__animated animate__fadeInUp space-y-4' onSubmit={handleSubmit}>
          <input 
            className='bg-[#ccd6f6] p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500' 
            type="text" 
            placeholder='Name' 
            name='name' 
            value={formData.name}
            onChange={handleChange}
            required 
          />
          <input 
            className='bg-[#ccd6f6] p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500' 
            type="email" 
            placeholder='Email' 
            name='email' 
            value={formData.email}
            onChange={handleChange}
            required 
          />
          <input 
            className='bg-[#ccd6f6] p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500' 
            type="text" 
            placeholder='Subject' 
            name='subject' 
            value={formData.subject}
            onChange={handleChange}
            required 
          />
          <textarea 
            className='bg-[#ccd6f6] p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500' 
            name="message" 
            rows="5" 
            placeholder='Message' 
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button 
            className='text-white border-2 hover:bg-cyan-500 hover:border-cyan-500 px-4 py-3 my-8 mx-auto flex items-center rounded-md transition-all duration-300 transform hover:scale-105'
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
