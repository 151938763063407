// About.js

import React from "react";
import { Link } from "react-scroll"; 
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const About = () => { 
  return (
    <div
      name="about"
      id="about"
      className="w-full h-screen bg-[#0a192f] text-gray-300"
    >
      <div className="flex flex-col justify-center items-center w-full h-full sm:mb-8">
        <div className=" py-16 rounded-md bg-cyan-800 flex flex-col justify-center items-center w-full sm:w-4/6">
          <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8 mb-4">
            <div>
            </div>
            <div className="lg:text-left sm:text-left pb-8 pl-4">
            <p className="text-4xl text-center font-bold inline border-b-4 border-cyan-500">
                About
              </p>
            </div>
          </div>
          <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-9 px-4">
            <div className="sm:text-left  text-3xl  font-bold">
              <p>
                Hello again👋🏾. Please take a
                look around. 👩🏾‍💻☯🍪

              </p>
            </div>
            <div>
              <p>
                {" "}
                A software developer with experience in building Responsive and
                Scalable Web apps. . In addition to software development, I possess a decade of IT experience
                across several industries such as ITS/Transportation, Automotive, Health Care, and Digital Marketing.
              </p>
            </div>
          </div>
        </div>
       
        <div>
          <Link
            to="contact"
            smooth
            duration={500}
            className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-green-600 cursor-pointer"
          >
            Contact Me
            <span className="group-hover:rotate-90 duration-300">
              <MdKeyboardDoubleArrowRight size={25} className="ml-3" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default About;